import './src/css/fonts.css';
import './src/css/typo.css';
import ReactDOM from 'react-dom/client';

//replacing the hydrateFunction prevents React errors #418 and #423 in minified production build
//as reported in https://github.com/gatsbyjs/gatsby/discussions/36232
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
